.nav-link {
  font-size: 1rem;
}

.navbar-brand {
  font-size: 1.2rem !important;
}

#logo {
  max-width: 100%;
  height: auto;
  width: 600px;
}

#sign-in-up {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.add-setup-btn {
  height: 7vh;
  font-size: 20px;
}

#dropdown-menu-change-setup {
  font-size: .8em;
}

.list-group{
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}


.faBtn {
  background: transparent;
  border: none !important;
  filter: brightness(1);
}

.faBtn:hover {
  filter: brightness(0.5);
}

html, body, #root {
  min-width: 100vw;
}


